<template>
    <div class="stars-container">
      
      <img @click="setStars(i)" v-for="i in 5" :key="'star-' + i" :src="i <= stars ? require('../assets/icons/star.png') : require('../assets/icons/star_grey.png') " style="width: 24px;"/>
    </div>
</template>
<script>
export default {
  name: 'stars',
  props: {
    stars: Number
  },

  methods: {
    setStars(i){
      console.log(i)
      this.$emit('change', i)
    }
  }
}
</script>
<style scoped>
.stars-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}
</style>